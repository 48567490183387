<script setup lang="ts">
import { nanoid } from 'nanoid'
import type { ILeaseVariationDocument } from '@register'

defineProps<{ amendments: ILeaseVariationDocument[] }>()

const resolveKey = (amendment: ILeaseVariationDocument) => {
  return amendment.document?.id ?? nanoid() + amendment.variations?.[0].id
}
</script>

<template>
  <div v-auto-animate="{ duration: 100 }">
    <div class="mb-10">
      <AmendmentDocument
        v-for="amendment in amendments"
        :key="resolveKey(amendment)"
        :amendment="amendment"
      />
    </div>
  </div>
</template>
